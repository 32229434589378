.coming-soon {
  padding: emCal(16, 50);
  @include mq(md) {
    padding: 0;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 90vw;
    background: {
      image: url("/assets/images/pages/coming-soon/bg.png");
      position: center;
      size: 100% 100%;
      repeat: no-repeat;
    }
    @include mq(md) {
      background: none;
      width: 100%;
      height: 100vh;
    }
    main {
      text-align: center;
      .section-title {
        margin-bottom: emCal(16, 15);
      }
      h3 {
        @include typo($fsz: emCal(16, 14));
        margin-bottom: emCal(14, 100);
      }
    }
  }
  &-countdown {
    display: flex;
    justify-content: center;
    padding-bottom: emCal(16, 40);
    margin-bottom: emCal(16, 80);
    border-bottom: 1px solid setShadeColor(light);
    &-item {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-right: emCal(16, 15);
        @include mq(md) {
          margin-right: 0;
        }
      }
      text-align: center;
      padding: emCal(16, 5) emCal(16, 12);
      @include mq(md) {
        padding: 0 emCal(14, 5);
      }
      h6 {
        @include typo($fsz: emCal(16, 60), $fw: 600, $color: setColor(green));
        @include mq(md) {
          font-size: emCal(14, 30);
        }
      }
      span {
        text-transform: uppercase;
      }
    }
  }
  p {
    @include typo($fsz: emCal(16, 18));
    margin-bottom: emCal(18, 30);
  }
}
