.order-complete {
  margin-bottom: $section-spacing;
  text-align: center;
  padding-bottom: emCal(16, 70) 0;
  h1 {
    @include typo($fsz: emCal(16, 27), $lh: emCal(27, 35), $fw: 600);
    span {
      color: setColor(green);
    }
  }

  h4 {
    @include typo($fsz: emCal(16, 20), $lh: emCal(27, 27), $fw: 600);
  }
}
