.shop-order-step {
  margin-bottom: $section-spacing;
  .step-block {
    overflow: hidden;
    position: relative;
    text-align: center;
    background: #fff;
    @include mq(md) {
      margin-bottom: emCal(16, 10);
    }
    &.active {
      background-color: setColor(green);
      .step {
        h2 {
          color: #fff;
        }
        span {
          color: rgba(255, 255, 255, 0.25);
        }
        &:before {
          background-color: setColor(green);
        }
      }
    }
    &:before {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      left: -43px;
      border: 1px solid setShadeColor(light);
      width: 60px;
      height: 60px;
      transform: rotate(45deg);
      background: #fff;
    }
    &:after {
      content: "";
      position: absolute;
      border-width: 1px 0;
      border-style: solid;
      border-color: #ededed;
      width: calc(100% - 30px);
      height: 100%;
      z-index: 5;
      left: 0;
      top: 0;
    }
    .step {
      height: 60px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      h2 {
        @include typo($fw: 500, $fsz: emCal(16, 20));
        display: block;
        text-transform: uppercase;
        position: unset;
        z-index: 2;
      }
      span {
        @include typo(
          $fw: 600,
          $fsz: emCal(16, 48),
          $color: rgba(45, 44, 64, 0.1)
        );
        position: absolute;
        display: block;
        right: 35px;
        user-select: none;
        z-index: 5;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 12px;
        border: 1px solid setShadeColor(light);
        border-width: 1px 1px 0 0;
        width: 60px;
        height: 60px;
        transform: rotate(45deg);
        background-color: #fff;
        z-index: 2;
      }
      &:after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        right: -30px;
        border-style: solid;
        border-color: #fff #fff #fff transparent;
        border-width: 30px;
        width: 60px;
        height: 60px;
        background-color: #fff;
      }
    }
  }
}
