$root-color: (
  green: #88c74a,
  pink: #fd5f5c,
  coffee: #f79823,
  light: #eaeff4,
);

$colors: (
  green: var(--green-color),
  pink: var(--pink-color),
  coffee: var(--coffee-color),
  light: var(--light-color),
);

$shades: (
  dark: #252525,
  medium: #636363,
  regular: #b2b2b2,
  light: #ebebeb,
  border: #f5f5f5,
);

$rem-baseline: 16;
$transition: 0.2s ease-in-out;
$prefixes: (webkit, moz, ms, o);

$container: 1170px;
$container-fluid: 1800px;
$section-spacing: remCal(60);

$breakpoint: (
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
  xxxl: "1600px",
);

$font-weight: (
  300: "CeraPro Light",
  400: "CeraPro Regular",
  500: "CeraPro Medium",
  600: "CeraPro Bold",
  700: "CeraPro Black",
);
