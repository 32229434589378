.how-to-order {
  .step {
    width: 80%;
    margin: 32px auto;
    line-height: 120%;
  }

  .slick-slider {
    margin-top: 32px;
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      display: none !important;
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;

    & > li {
      display: inline-block;
      background-color: transparent;
      padding-right: 8px;

      &:not(:last-child) {
        margin-right: emCal(16, 5);
      }

      button {
        height: 40px;
        width: 40px;
        border: none;
        background-color: #e0e0e0;
        border-radius: 999px;
      }

      &.slick-active {
        button {
          background-color: setColor(#88c74a);
        }
      }
    }
  }

  .payment-gateway,
  .reminder {
    font-family: 'CeraPro Bold';
  }
}

@media (max-width: 768px) {
  .how-to-order {
    button {
      height: 30px !important;
      width: 30px !important;
    }

    .step {
      font-size: 16px;
    }
  }
}
