.quantity-selector {
  border: 1px solid setShadeColor(light);
  border-radius: emCal(16, 5);
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-number {
    text-align: center;
    width: 80%;

    @include mq(sm) {
      width: 70%;
    }
  }
  &-controller {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    &.cart-controller {
      width: unset;
    }

    @include mq(sm) {
      width: 30%;
    }
  }
}
