.introduction-one {
  display: flex;
  margin-bottom: $section-spacing;
  @include mq(md) {
    // flex-wrap: wrap;
  }
  &-item {
    width: 33.3333333%;
    flex-grow: 1;
    transition: $transition;
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: emCal(16, 35);
    overflow: hidden;
    img {
      @include imgCenter(emCal(16, 265), emCal(16, 370), contain);
      margin-bottom: emCal(16, 35);
    }
    .ant-btn {
      height: emCal(16, 45);
      line-height: emCal(16, 45);
      padding: 0 emCal(16, 30);
      opacity: 0;
      transform: translateY(50px);
      text-transform: uppercase;
      font-family: getFontWeight(600);
      @include mq(md) {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &:hover {
      flex-grow: 1;
      width: 50%;
      .ant-btn {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @include mq(md) {
      width: 50%;
      padding: 0;
      padding-bottom: emCal(16, 16);
    }
  }
  // @include md(md) {
  //   flex-direction: column;
  // }
}

.introduction-two {
  margin-bottom: $section-spacing;
  &-wrapper {
    background: {
      image: url('/assets/images/sections/introduction/two/bg.png');
      size: cover;
      position: center;
    }
  }
  &-image {
    img {
      max-width: 100%;
    }
  }
  &-content {
    p {
      @include typo($color: setShadeColor(medium), $lh: emCal(16, 26));
      margin-bottom: emCal(16, 30);
    }
    img {
      margin-bottom: emCal(16, 30);
    }
    h3 {
      @include typo($fw: 600, $fsz: emCal(16, 22));
      margin-bottom: emCal(16, 10);
    }
    h5 {
      @include typo($fsz: emCal(16, 12), $fw: 600, $color: setColor(coffee));
    }
  }
}

.introduction-three {
  background: {
    image: url('/assets/images/sections/introduction/three/bg.png');
    position: center;
    size: cover;
    attachment: fixed;
  }
  padding: emCal(16, 120) 0;
  margin-bottom: $section-spacing;
  &-content {
    .introduction-three-steps {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      &-item {
        text-align: center;
        position: relative;
        &:not(:last-child) {
          &:after {
            content: '';
            display: inline-block;
            background: {
              image: url('/assets/images/sections/introduction/three/arrow.png');
              repeat: no-repeat;
            }
            width: emCal(16, 190);
            height: emCal(16, 35);
            display: block;
            position: absolute;
            left: 70%;
            top: 0;
          }
        }
        img {
          @include imgCenter(emCAl(16, 130), emCal(16, 130));
          margin-bottom: emCal(16, 20);
        }
        h3 {
          @include typo($fsz: emCal(16, 18), $fw: 600, $color: setColor(coffee));
          margin-bottom: emCal(18, 15);
        }
        p {
          @include typo($fsz: emCal(16, 18), $color: #fff);
        }
      }
      @include mq(md) {
        grid-template-columns: repeat(2, 1fr);
        .introduction-three-steps-item {
          &:not(:nth-child(n + 3)) {
            margin-bottom: emCal(14, 30);
          }
          &:nth-child(2) {
            &:after {
              display: none;
            }
          }
        }
        &:nth-child(2) {
        }
      }
      @include mq(sm) {
        grid-template-columns: 1fr;
        .introduction-three-steps-item {
          &:after {
            display: none;
          }
          &:not(:last-child) {
            margin-bottom: emCal(14, 30);
          }
        }
      }
    }
  }
}

.introduction-four {
  background: {
    image: url('/assets/images/sections/introduction/four/bg.png');
    position: center;
    size: cover;
    attachment: fixed;
  }
  padding: emCal(16, 100) 0;
  margin-bottom: $section-spacing;
  .dow-one-image {
    @include imgCenter(emCal(16, 205), emCal(16, 205), contain);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .dow-one-content {
    .dow-one-content__item {
      &-image {
        background-color: #fff;
      }
      &-content {
        h5,
        p {
          color: #fff;
        }
      }
    }
  }
}

.introduction-five {
  margin-bottom: $section-spacing;
  padding-top: emCal(16, 50);
  background: {
    image: url('/assets/images/sections/introduction/five/bg.png');
    size: cover;
    text-align: center;
  }
  .section-title {
    h2 {
      font-size: emCal(16, 36);
    }
  }
  &-image {
    img {
      @include imgCenter($type: contain);
      max-width: 100%;
    }
  }
  &-benefits {
    &__item {
      @extend .dow-one-content__item;
      &-image {
        @extend .dow-one-content__item-image;
      }
      &-content {
        @extend .dow-one-content__item-content;
      }
    }
  }
}

.introduction-six {
  margin-bottom: $section-spacing;
  .section-title {
    h2 {
      font-size: emCal(16, 36);
    }
  }
  &-content {
    &__item {
      height: emCal(16, 330);
      position: relative;
      overflow: hidden;
      border-radius: emCal(16, 5);
      background: {
        size: cover;
        position: center;
      }
      &:hover {
        main {
          top: 0;
          left: 0;
        }
      }
      main {
        transition: $transition;
        position: absolute;
        top: -100%;
        left: -100%;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mq(md) {
          top: 0;
          left: 0;
        }
        h3 {
          @include typo($fsz: emCal(16, 22), $fw: 600, $color: #fff);
          margin-bottom: emCal(22, 15);
        }
        h5 {
          @include typo($fsz: emCal(16, 12), $fw: 500, $color: #fff, $up: true);
          margin-bottom: emCal(13, 30);
        }
      }
    }
  }
}
