.arrow-center {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    padding: 0;
    &.slick-next {
      right: emCal(16, 30);
    }
    &.slick-prev {
      left: emCal(16, 30);
    }
  }
}

.zoom-in {
  overflow: hidden;
  display: inline-block;
  img {
    @include imgCenter;
    transition: $transition;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.up-down-anim {
  animation: upDown 1s infinite;
}

.up-down-anim-hover {
  &:hover {
    img {
      animation: upDown 1s infinite;
    }
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sticky-outer-wrapper,
.sticky-inner-wrapper {
  background-color: white;
}

.cart-total {
  position: absolute;
  background-color: var(--green-color);
  top: -5px;
  right: -15px;
  height: 18px;
  width: 16px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  // background-color: white;
  // border-radius: 9px;
  // box-shadow: 4px 10px 24px 2px rgba(125, 125, 125, 0.75);
}

.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  bottom: 0;
  max-height: unset;
}
