.function-items {
  .cart-number {
    font-size: emCal(16, 14) !important;
  }
  &-item {
    @include link($fsz: emCal(16, 20));
    &:not(:last-child) {
      margin-right: emCal(20, 15);
    }
    &:last-child {
      span {
        font-size: emCal(20, 16);
        font-family: getFontWeight(600);
        margin-left: emCal(16, 15);
      }
    }
  }
}
